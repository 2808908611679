@import "src/style/variables";
@import "src/style/mixins";

.container {
  padding: 40px 0 150px;
  display: flex;
  flex-direction: column;
  height: 100%;

  @include xxl {
    padding: 40px 0;
  }
}

.newText {
  font-size: 14px;
  line-height: 20px;
  color: #969696;
  padding: 0 40px;
}

.noText {
  font-size: 18px;
  line-height: 20px;
  color: #343B4C;
  padding: 0 40px;
}

.error {
  margin-top: 11px;
  font-size: 14px;
  line-height: 20px;
  color: $error;
  padding: 0 40px;
}

.notification {
  margin-top: 28px;
  overflow-y: auto;
  height: 100%;
  padding: 0 40px;

  &_item {
    background: #FFFFFF;
    box-shadow: 0 8px 16px rgba(50, 50, 71, 0.06);
    border-radius: 10px;
    padding: 28px;
    margin-top: 10px;
    position: relative;

    &:first-child {
      margin-top: 0;
    }
  }

  &_date {
    font-size: 14px;
    line-height: 20px;
    color: #969696;
  }

  &_text {
    font-size: 16px;
    line-height: 24px;
    color: #141414;
    margin-top: 11px;
  }

  &_link {
    margin-top: 11px;
  }

  &_more {
    margin-top: 20px;
    width: 100%;
  }

  &_close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 15px;
    height: 15px;

    svg {
      width: 100%;
      height: 100%;
      fill: $main;
    }
  }
}
