.changePasswordContainer {
  .description {
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #595959;
    padding-bottom: 24px;
  }

  .btn {
    width: 100%;
  }

  .successfull {
    padding-top: 10px;
  }
}
