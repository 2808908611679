.preloader {
  display: flex;
  justify-content: center;
  height: 100%;

  &_center {
    align-items: center;
  }
}

.spinner {
  width: 70px;
  height: 70px;
}
