@import "src/style/variables";
@import "src/style/mixins";

.container {
  padding: 35px 0 150px;
  height: 100%;

  @include xxl {
    padding: 35px 0;
  }

  &_inner {
    height: 100%;
    overflow-y: auto;
    padding: 0 40px;
  }
}

.title {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000000;

  @include xxl {
    display: none;
  }

  &_back {
    display: none;

    @include xxl {
      display: flex;
    }
  }
}

.curator {
  background: #FFFFFF;
  box-shadow: 0 8px 16px rgba(50, 50, 71, 0.06);
  border-radius: 10px;
  margin-top: 17px;
  padding: 22px 24px;

  &_title {
    display: flex;
    align-items: center;
    color: #000;
    transition-duration: .3s;

    &:hover {
      color: $main;
    }
  }

  &_photo {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    overflow: hidden;
    flex-shrink: 0;
    margin-right: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $main;
    color: $white;
    font-weight: 600;
    font-size: 22px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &_name {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }

  &_email {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #6483C0;
    margin-top: 23px;
    display: block;
  }

  &_phone {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #6483C0;
    margin-top: 6px;
    display: block;
  }

  &_chat {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: $main;
    margin-top: 23px;
  }
}

.courses {
  margin-top: 26px;
}

.remainder {
  background: $white;
  box-shadow: 0 8px 16px rgba(50, 50, 71, 0.06);
  border-radius: 10px;
  margin-top: 17px;
  padding: 20px 22px;
  display: flex;
  align-items: center;
  margin-bottom: 17px;

  &_count {
    width: 73px;
    height: 73px;
    background: #EEDBE8;
    color: $main;
    font-weight: bold;
    font-size: 36px;
    line-height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    flex-shrink: 0;
    margin-right: 15px;
  }

  &_title {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #000;
  }
}
