.table {
  margin-top: 20px;
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.status {
  font-size: 12px;
  line-height: 20px;
  padding: 1px 8px;
  border: 1px solid;
  border-radius: 2px;
  display: inline-block;
  margin: 0 auto;

  &__paid {
    background: #F6FFED;
    border-color: #B7EB8F;
    color: #52C41A;
  }

  &__pending {
    background: #FFF7E6;
    border-color: #FFD591;
    color: #FA8C16;
  }

  &__expired {
    background: #FFF1F0;
    border-color: #FFA39E;
    color: #F5222D;
  }

  &__limit {
    //background: #FFF1F0;
    border-color: #000000;
    color: #000000;
  }
}

.type {
  font-size: 12px;
  line-height: 20px;
  padding: 1px 8px;
  border: 1px solid;
  border-radius: 2px;
  display: inline-block;
  margin: 0 auto;

  &__proforma {
    background: #FFF7E6;
    border-color: #FA8C16;
    color: #FA8C16;
  }

  &__prepayment {
    background: #E6F7FF;
    border-color: #5DA1CB;
    color: #5DA1CB;
  }

  &__check {
    background: #F6FFED;
    border-color: #B7EB8F;
    color: #52C41A;
  }

  &__credit {
    background: #FFF1F0;
    border-color: #FFA39E;
    color: #F5222D;
  }
}

.number {
  display: block;
  color: #1890ff;

  &:hover {
    color: #1890ff;
    text-decoration: underline;
  }
}
