@import "src/style/variables";

.container {
  height: 100%;
  overflow-y: auto;
  padding: 38px 0 0;
  display: flex;
  flex-direction: column;

  &_inner {
    //  padding: 0 40px;
  }
}

.back_title {
  display: flex;
  align-items: center;

  &_photo {
    border-radius: 100%;
    background-color: $main;
    color: $white;
    margin-right: 15px;
    width: 34px;
    height: 34px;
    flex-shrink: 0;
    font-size: 15px;
    position: relative;

    &_item {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
    }

    &_online {
      &:before {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        background: #2CC069;
        border: 2px solid #FFFFFF;
        top: -2px;
        right: -2px;
        border-radius: 100%;
      }
    }
  }
}

.chat {
  overflow-y: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;

  &_spinner {
    display: flex;
    justify-content: center;
    width: 100%;

    svg {
      width: 70px;
      height: 70px;
    }
  }

  &_new_day {
    font-size: 12px;
    line-height: 20px;
    color: #ADB5BD;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    white-space: nowrap;

    &:before,
    &:after {
      height: 1px;
      content: '';
      background-color: #EDEDED;
      flex: 1;
    }

    &:before {
      margin-right: 16px;
    }

    &:after {
      margin-left: 16px;
    }
  }

  &_scrollable {
    padding: 0 19px 0 16px;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    overflow-y: auto;

  }

  &_message {
    background: $white;
    border-radius: 16px 16px 16px 0;
    margin-bottom: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 100%;

    &_text {
      width: 100%;

      a {
        color: #3E97D7;
        transition-duration: .3s;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .time {
      font-size: 10px;
      line-height: 16px;
      color: #ADB5BD;
      margin-top: 4px;
    }

    &_parent {
      background: #EDEDED;
      border-radius: 4px;
      overflow: hidden;
      padding: 8px 12px;
      position: relative;
      margin-bottom: 4px;
      font-size: 14px;
      line-height: 24px;
      color: #1B2B48;

      &_from {
        font-weight: 600;
        font-size: 10px;
        line-height: 16px;
        color: #002DE3;
        margin-bottom: 4px;
      }

      &:before {
        content: '';
        width: 4px;
        height: 100%;
        background-color: $main;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &_me {
      border-radius: 16px 16px 0 16px;
      background: $main;
      color: $white;
      margin-left: auto;
      align-items: flex-end;

      .time {
        color: $white;
      }

      .chat_message_parent {
        text-align: left;
        background: #F7E4F0;

        &:before {
          background-color: #ad5791;
        }

        .chat_message_parent_from {
          color: $main;
        }
      }
    }

    &_files {
      max-width: 100%;

      &_item {
        margin-bottom: 4px;
        border-radius: 4px;
        max-width: 100%;
        display: block;
      }

      &_file {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        background: #EDEDED;
        color: #000;
        padding: 5px;

        svg {
          margin-right: 5px;
        }
      }
    }
  }
}

.field {
  margin-top: auto;

  &_content {
    padding: 18px 16px;
    display: flex;
    align-items: flex-end;
    background-color: $white;
    border: 1px solid #EDEDED;
  }

  &_upload {
    margin: 7px 16px auto 0;

    &_list {
      display: block;
      background-color: $white;
      padding-top: 8px;
    }
  }

  &_input {
    padding: 6px 8px;
    background: #F7F7FC;
    border-radius: 4px;
    border: none;
  }

  &_send {
    flex-shrink: 0;
    margin-left: 15px;

    svg {
      fill: $main;
    }

    &:disabled {
      cursor: default;

      svg {
        fill: $lightGray;
      }
    }
  }

  &_spinner {
    width: 18px;
    height: 18px;
    fill: $main;
  }
}

.parent {
  background-color: $white;
  padding: 8px 16px 8px 24px;
  display: flex;
  align-items: center;
  white-space: nowrap;

  &:before {
    content: '';
    width: 2px;
    height: 100%;
    background-color: $main;
    margin-right: 5px;
    flex-shrink: 0;
  }

  &_text {
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px;
  }

  &_close {
    margin-left: auto;

    svg {
      transition-duration: .3s;
    }

    &:hover svg {
      fill: $main;
    }
  }
}
