@import "src/style/mixins";

.container {
  margin-top: 24px;
}

.section {
  background: #FFFFFF;
  border: 1px solid rgba(168, 168, 168, 0.25);
  border-radius: 4px;
  padding: 25px 30px;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  &__disabled {
    background: #F7F7F7;
  }

  h2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 40px;
    color: #11263C;
    margin: 0 0 23px;
  }
}

.item_title {
  font-size: 14px;
  line-height: 18px;
  color: #8E8E8E;
  margin-bottom: 9px;
}

.item_content {
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.infoStudent {
  border-right: 1px solid #D9D9D9;

  @include xl {
    border-right: none;
  }
}
