.container {
  display: flex;
  align-items: center;
  margin-top: 70px;
  justify-content: center;

  .not_found_content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    h1 {
      font-weight: 700;
      font-size: 200px;
      line-height: 224px;
      color: #6a1d51;
      margin: 0;
    }

    .description {
      font-size: 20px;
      line-height: 27px;
      text-align: right;
    }

    .btn_error {
      button {
        margin-top: 50px;
        border-radius: 46px;
      }
    }
  }

  .not_found_image {
    display: flex;
  }
}
