$text-gray: #647781;
$main: #6a1d51;
$black: #000;
$numeric: #8e9bff10;
$pink: #ac0676;
$breadcrumbsGray: #6c6c6c;
$white: #fff;
$lightGray: #adadad;
$calendarGray: #f6f7ff;
$calendarTextGray: #bcbcbc;
$error: #ff4d4f;
