@import "src/style/variables";

.wrapper {
  margin-bottom: 24px;
  position: relative;
}

.error {
  position: absolute;
  top: 100%;
  left: 0;
  color: $error;
  font-size: 12px;
}

.placeholder {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  line-height: 24px;
  color: #BFBFBF;
  z-index: 2;
  transition-duration: .3s;

  &__textarea {
    transform: translateY(0);
    top: 18px;
  }

  &_icon {
    left: 32px;
  }

  &_active {
    font-size: 12px;
    top: 0;
    left: 12px;
    transform: none;
  }

  &_required {
    color: #FF0000;
  }
}

.label {
  &.readonly {
    input,
    textarea {
      background-color: $white;
      color: #000;
      cursor: default;
    }
  }
}
