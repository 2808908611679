@import "src/style/variables";
@import "src/style/mixins";

.header {
  padding: 26px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include xl {
    justify-content: flex-end;
  }

  @include lg {
    justify-content: space-between;
  }
}

.right_block {
  display: flex;
  align-items: center;

  &_item {
    padding: 0 16px;
    display: block;

    &:last-child {
      margin-right: 0;
    }

    &_chat {
      display: none;

      @include xxl {
        display: block;
      }
    }

    &_info {
      display: none;

      svg {
        fill: #CACCCF;
        width: 20px;
        height: 20px;
      }

      @include xxl {
        display: block;
      }
    }
  }
}

.language {
  &_btn {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #000;
    text-transform: uppercase;
    transition-duration: .3s;

    &_flag {
      margin-right: 10px;

      svg {
        width: 22px;
        height: 16px;
      }
    }

    &_arrow {
      margin-left: 17px;
      width: 12px;
      height: 7px;
      transition-duration: .3s;
    }

    &:hover {
      color: $main;
    }

    &.open {
      .language_btn_arrow {
        transform: rotate(180deg);
      }
    }
  }

  &_select_item {
    display: flex;
    align-items: center;
    color: #000;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    margin-bottom: 18px;
    transition-duration: .3s;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      color: $main;
    }

    svg {
      width: 22px;
      height: 16px;
      margin-right: 10px;
    }
  }
}


.profile {
  display: flex;
  align-items: center;

  @include lg {
    display: none;
  }

  &_name {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #11263C;
  }

  &_btn {
    display: flex;
    align-items: center;
    margin: 0;

    &.open {
      .profile_arrow {
        transform: rotate(180deg);
      }
    }
  }

  &_avatar {
    width: 50px;
    height: 50px;
    border-radius: 16px;
    background-color: $main;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    font-weight: 600;
    font-size: 22px;
    overflow: hidden;
  }

  &_arrow {
    margin-left: 15px;
    width: 12px;
    height: 7px;
    transition-duration: .3s;
  }

  &_popover {
    display: flex;
    flex-direction: column;

    &_item {
      font-size: 14px;
      line-height: 22px;
      color: #595959;
      margin-bottom: 10px;
      text-align: left;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.breadcrumbs {
  @include xl {
    display: none;
  }
}

.menu_btn {
  display: none;

  @include lg {
    display: block;
  }

  svg {
    fill: $main;
  }

  &:hover {
    svg {
      fill: darken($main, 10%);
    }
  }
}

.logo {
  display: none;
  margin-left: 20px;

  @include lg {
    display: block;
  }

  svg {
    width: 114px;
    height: 40px;
  }
}

.left_block {
  display: flex;
  align-items: center;
}

.changeLang {
  @include lg {
    display: none;
  }
}
