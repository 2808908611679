@import "src/style/variables";
@import "src/style/mixins";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include xl {
    flex-direction: column;
    align-items: flex-start;
  }

  &_profile {
    display: flex;
    align-items: center;

    &_photo {
      width: 96px;
      height: 96px;
      margin-right: 25px;
      flex-shrink: 0;
      position: relative;

      &__loading {
        border: 1px solid $main;
        border-radius: 100%;
      }

      &_item {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background-color: $main;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 40px;
      }

      &_upload {
        width: 36px;
        height: 36px;
        background: $white;
        border: 1px solid #EEEEEE;
        position: absolute;
        bottom: 0;
        right: 0;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
          fill: #969696;
          transition-duration: .3s;
        }

        &:hover {
          svg {
            fill: $main
          }
        }
      }
    }

    &_name {
      font-weight: bold;
      font-size: 24px;
      line-height: 40px;
      color: #11263C;
    }

    &_role {
      color: #969696;
    }
  }
}

.actions {
  display: flex;

  @include xl {
    margin-top: 20px;
  }

  @include sm {
    flex-direction: column;
  }

  &_item {
    margin-right: 20px;

    @include sm {
      margin-right: 0;
      margin-bottom: 10px;
    }

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
  }

  &_delete {
    background: #B81A1A;
    border: 1px solid #D9D9D9;
    color: $white;

    &:hover {
      background: darken(#B81A1A, 5%);
      border: 1px solid #D9D9D9;
      color: $white;
    }
  }
}

.menu {
  margin-top: 22px;

  li {
    padding: 0 !important;
  }

  &_link {
    font-weight: 500;
    font-size: 14px;
    padding: 0 20px;
  }
}

.main {
  margin-top: 22px;
}
