@import "src/style/variables";
@import "src/style/mixins";

.actions {
  margin-bottom: 26px;
  display: flex;

  @include sm {
    flex-direction: column;
  }

  &_select {
    margin-left: 20px;
    width: 180px;

    @include sm {
      margin: 5px 0 0;
      width: 100%;
    }
  }
}

.material {
  border: 1px solid rgba(168, 168, 168, 0.25);
  border-radius: 4px;
  padding: 37px 32px 32px;
  position: relative;

  &:hover {
    .material_actions {
      opacity: 1;
      visibility: visible;
    }
  }

  &_title {
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: #000000;
  }

  &_description {
    font-size: 18px;
    line-height: 24px;
    color: #969696;
    margin-top: 25px;
  }

  &_link {
    margin-top: 25px;
  }

  &_actions {
    position: absolute;
    top: 5px;
    right: 10px;
    display: flex;
    opacity: 0;
    visibility: hidden;
    transition-duration: .3s;

    & > * {
      margin-right: 10px;
      transition-duration: .3s;

      &:last-child {
        margin-right: 0;
      }

      &:disabled {
        opacity: 0.5;
      }
    }
  }

  &_edit {
    &:hover {
      color: $main;
    }
  }

  &_delete {
    color: #B81A1A;

    &:hover {
      color: darken(#B81A1A, 5%);
    }
  }
}

.pagination {
  text-align: right;
  margin-top: 30px;
}

.no_materials {
  text-align: center;
  font-size: 18px ;
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
