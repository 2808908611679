@import "src/style/mixins";

.container {
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  background-color: #F6F6F6;
  min-height: 100vh;
}

.main {
  background-color: #fff;
  width: calc(100% - 348px);
  padding: 0 30px 30px 26px;
  display: flex;
  flex-direction: column;

  &_right {
    width: calc(100% - 348px - 348px);

    @include xxl {
      width: calc(100% - 348px);
    }
  }

  @include lg {
    width: 100%;
    padding: 0 20px 30px;
  }
}
