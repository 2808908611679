@import "src/style/variables";

.btn {
  width: 100%;
  margin-top: 24px;
}

.text {
  text-align: center;
}

.login {
  text-align: center;
  margin-top: 24px;
}

.error {
  color: $error;
  margin-bottom: 10px;
}
