@keyframes splatAnim {
  0% {
    transform: rotateZ(0);
  }
  10% {
    transform: rotateZ(36deg) scale(1.22, 1.28);
  }
  20% {
    transform: rotateZ(72deg) scale(1.15, 1.23);
  }
  30% {
    transform: rotateZ(108deg) scale(1.1, 1.15);
  }
  40% {
    transform: rotateZ(144deg) scale(1.03, 1.22);
  }
  50% {
    transform: rotateZ(180deg) scale(1.1, 1.14);
  }
  60% {
    transform: rotateZ(216deg) scale(1.16, 1.18);
  }
  70% {
    transform: rotateZ(252deg) scale(1.08, 1.12);
  }
  80% {
    transform: rotateZ(288deg) scale(1.14, 1.23);
  }
  90% {
    transform: rotateZ(324deg) scale(1.1, 1.13);
  }
  100% {
    transform: rotateZ(360deg) scale(1);
  }
}