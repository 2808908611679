.link {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #3E97D7;
  display: flex;
  align-items: center;

  &:hover {
    color: #3E97D7;
    text-decoration: underline;
  }

  svg {
    margin-right: 10px;
  }
}
