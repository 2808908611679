@import "src/style/variables";

.add_fields {
  display: flex;
  align-items: center;
  background: #F8F8F8;
  border-radius: 5px;
  margin-bottom: 25px;
  width: 100%;
  padding: 11px 17px;
  color: $main;

  svg {
    margin-right: 20px;
    width: 14px;
    height: 14px;
  }

  &_title {
    font-size: 16px;
    line-height: 18px;
  }

  &_description {
    font-size: 13px;
    line-height: 18px;
  }

  &_active {
    color: $white;
    background-color: $main;
  }
}
