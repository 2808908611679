@import "src/style/variables";

.menuContainer {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  margin-top: -3px;
  background: #fff;
}

.subBlock {
  border-color: #FFFFFF !important;
  margin: 0 !important;
  padding-left: 0 !important;

  span {
    a {
      span {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        align-items: center;
        color: #595959;
      }
    }

    &:hover * {
      color: #6A1D51
    }
  }

  a {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  ul {
    background: #F4F4F4 !important;
  }

  .itemBlock {
    height: 32px !important;
    padding-left: 25px !important;
    background: #F4F4F4;
  }

  div {
    span {
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      align-items: center;
      color: #595959
    }

    &:hover * {
      color: #6A1D51
    }
  }

  & > div {
    padding: 5px 8px 5px 12px !important;
    height: 100% !important;
  }
}

.itemBlock {
  padding: 5px 8px 5px 12px !important;
  div {
    margin: 0 !important;
  }
  span {
    a {
      span {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        align-items: center;
        color: #595959;
      }
    }

    &:hover * {
      color: #6A1D51
    }
  }
}

.btnAdd {
  background: $main;
  border-radius: 2px;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  width: 264px;

  span {
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    padding-left: 44px;
  }
}
