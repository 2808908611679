@import "src/style/variables";

.title {
  margin-bottom: 26px;
}

.event {
  padding: 10px 16px;
  font-size: 12px;
  line-height: 26px;
  box-shadow: 0 4px 6px rgb(0 0 0 / 12%);
  height: 100%;
  border-radius: 5px;
  color: #fff;
  background: $main;

  &_title {
    font-weight: bold;
    line-height: 22px;
    text-decoration-line: underline;
    transition-duration: .3s;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      text-decoration-line: none;
    }
  }

  &_item {
    font-weight: 500;
    text-decoration-line: underline;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;

    &:hover {
      text-decoration-line: none;
    }

    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    svg {
      flex-shrink: 0;
      margin-right: 10px;
    }
  }
}

.menu {
  margin-bottom: 22px;

  &_link {
    font-weight: 500;
    font-size: 14px;
  }
}

.filters {
  display: flex;
  justify-content: space-between;
}
