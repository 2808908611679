@import "src/style/variables";
@import "src/style/animation";
@import "src/style/mixins";

.main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 48px 20px;
  position: relative;
  overflow: hidden;
}
.logo {
  text-align: center;
  margin-bottom: 60px;
  display: block;

  @include sm {
    margin-bottom: 40px;
  }
}

.lang {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.content {
  background: $white;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 24px;
  width: 330px;

  @include sm {
    width: auto;
  }
}
.title {
  font-size: 20px;
  line-height: 28px;
  font-weight: normal;
  text-align: center;
  color: #262626;
}
.description {
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #595959;
  margin-top: 16px;
}
.children {
  margin-top: 24px;
}
.splat_1 {
  position: absolute;
  z-index: -1;
  width: 94vw;
  height: 86vw;
  top: 0;
  right: 0;
  transform: translate(29%, -52%);

  svg {
    width: 100%;
    height: 100%;
    animation: splatAnim 90s linear infinite forwards;
  }
}
.splat_2 {
  position: absolute;
  z-index: -1;
  width: 142vw;
  height: 115vw;
  bottom: 0;
  left: 0;
  transform: translate(-57%, 79%);

  svg {
    width: 100%;
    height: 100%;
    animation: splatAnim 90s linear infinite forwards;
  }
}
.copy {
  font-size: 14px;
  line-height: 24px;
  color: #868686;
  margin-top: 50px;
  text-align: center;
}
