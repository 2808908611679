@import "src/style/variables";
@import "src/style/mixins";

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include sm {
    flex-direction: column;
    align-items: flex-start;
  }
}

.type {
  width: 180px;

  @include sm {
    margin-top: 20px;
    width: 100%;
  }
}

.targets {
  &_title {
    font-size: 24px;
    line-height: 31px;
    margin-top: 35px;
  }

  &_container {
    margin-top: 27px;
  }

  &_item {
    margin-right: 20px;
    border-radius: 96px;
    margin-bottom: 20px;
    padding: 16px 20px;
  }
}

.courses {
  margin-top: 20px;

  &_item {
    display: block;
    background: #FFFFFF;
    border: 1px solid rgba(168, 168, 168, 0.25);
    border-radius: 4px;
    padding: 27px 30px 36px;
    position: relative;
    overflow: hidden;
    color: #000;
    transition-duration: .3s;
    height: 100%;

    &:hover {
      color: #000;
      border-color: $main;
    }

    &__bought {
      opacity: 0.4;
    }

    &__inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &_bought {
      position: absolute;
      top: 14px;
      right: -23px;
      background-color: $main;
      color: $white;
      height: 25px;
      width: 98px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 10px;
      line-height: 13px;
      text-transform: uppercase;
      transform: rotate(45deg);
    }

    &_category {
      padding: 8px 18px;
      border-radius: 5px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #fff;
      background-color: $main;
    }

    &_title {
      font-weight: bold;
      font-size: 18px;
      line-height: 26px;
      text-transform: uppercase;
      margin-top: 15px;
      color: #000;
      transition-duration: .3s;

      &:hover {
        color: $main;
      }
    }

    &_time {
      display: flex;
      justify-content: space-between;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      margin-top: 15px;
      width: 100%;
    }

    &_regularity {
      margin-top: 17px;
      font-size: 16px;
      line-height: 24px;
    }

    &_lessonDuration {
      font-size: 16px;
      line-height: 24px;
    }

    &_description {
      margin-top: 15px;
      font-size: 16px;
      line-height: 24px;
      color: #838383;
    }

    &_price {
      font-weight: 500;
      font-size: 24px;
      line-height: 31px;
      margin-top: 17px;

      span {
        font-size: 14px;
      }
    }
  }

  &_noCourses {
    text-align: center;
  }
}

.pagination {
  text-align: right;
  margin-top: 30px;
}
