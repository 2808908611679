@import "src/style/variables";

.modal {
  &__center {
    text-align: center;
  }

  &_type {
    font-weight: bold;
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 16px;
  }

  &_time {
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 20px;
  }

  &_date {
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    margin-right: 10px;
  }

  &_title {
    font-weight: bold;
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 22px;
    color: $main;
  }

  &_info {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      color: #000;
      text-decoration: underline;

      &:hover {
        color: $main;
      }
    }
  }

  &_actions {
    margin-top: 45px;

    button {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &_loading {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    z-index: 10;
  }

  &_error {
    color: $error;
    margin-bottom: 15px;
  }
}

.individualBefore {
  width: 100%;
  margin-bottom: 15px;
}
