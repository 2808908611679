@import "src/style/variables";
@import "src/style/mixins";

.btn {
  width: 100%;
  margin-bottom: 24px;
}
.registration {
  text-align: center;
  margin-top: 12px;
}
.remember_me {
  display: flex;
  justify-content: center;
}

.error {
  color: $error;
  margin-bottom: 10px;
}
