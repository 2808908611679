.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.table {
  margin-top: 30px;
}

.number {
  display: block;
  color: #1890ff;

  &:hover {
    color: #1890ff;
    text-decoration: underline;
  }
}
