@import "src/style/mixins";
@import "src/style/variables";

.left_sitebar {
  flex-shrink: 0;
  width: 348px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: sticky;
  top: 0;
  overflow-y: auto;
  z-index: 5;

  @include lg {
    position: fixed;
    transform: translateX(-100%);
    background-color: #E5E5E5;
    width: 100%;
    top: 100px;
    padding-bottom: 100px;
    transition-duration: .3s;

    &.open {
      transform: translateX(0);
    }
  }
}

.logo {
  display: block;
  padding: 32px 0 0 38px;

  @include lg {
    display: none;
  }

  svg {
    width: 114px;
    height: 40px;
  }
}

.menu {
  padding-left: 16px;
  margin-top: 44px;

  @include lg {
    margin-top: 0;
  }
}

.menu_item {
  font-weight: 600;

  &_mobile {
    display: none !important;

    @include lg {
      display: flex !important;
    }
  }

  &:after {
    border-radius: 13px 0 0 13px;
  }
}

.settings {
  display: block;
  background: #F0F0F0;
  margin-top: auto;
  padding: 40px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;

  svg {
    margin-right: 20px;
  }
}

.homeworks {
  display: flex;
  margin: auto auto 59px;

  a {
    button {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      width: 258px;
    }
  }
}

.addItem {
  display: flex;
  width: 264px;
  margin: 15px auto 15px;
}

.profile {
  display: none;
  align-items: center;
  padding: 20px;

  @include lg {
    display: flex;
  }

  &_name {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #11263C;
  }

  &_avatar {
    width: 50px;
    height: 50px;
    border-radius: 16px;
    background-color: $main;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    font-weight: 600;
    font-size: 22px;
    overflow: hidden;
    flex-shrink: 0;
  }
}
