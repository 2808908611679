@import "src/style/variables";

.actions {
  display: flex;
  align-items: center;
  margin: 0 -10px;
  justify-content: center;

  &_item {
    margin: 0 10px;
    display: flex;
    align-items: center;

    svg {
      width: 15px;
      height: 15px;
      transition-duration: .3s;
    }

    &__pencil {
      fill: #ABABAB;

      &:hover {
        fill: $main;
      }
    }

    &__delete {
      color: #B81A1A;

      &:hover {
        color: darken(#B81A1A, 10%);
      }
    }
  }
}

.table {
  a {
    color: #3E97D7;
    transition-duration: .3s;

    &:hover {
      text-decoration: underline;
    }
  }
}
