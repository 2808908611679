@import "src/style/variables";
@import "src/style/mixins";

.container {
  flex-shrink: 0;
  width: 348px;
  margin-left: auto;
  position: sticky;
  height: 100vh;
  top: 0;

  @include xxl {
    position: fixed;
    top: 0;
    right: 0;
    background: #F6F6F6;
  }

  &Inner {
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.chat {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  height: 72px;
  background: #FFFFFF;
  border-radius: 15px;
  display: flex;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  padding: 0 0 0 24px;
  white-space: nowrap;
  transition-duration: .3s;

  @include xxl {
    display: none;
  }

  &_not_read {
    position: absolute;
    top: -2px;
    right: -2px;
    padding: 0 5px;
    background: #ff4d4f;
    border-radius: 8px;
    min-width: 16px;
    height: 16px;
    font-size: 10px;
    line-height: 16px;
    color: #fff;
    box-shadow: 0 0 0 1px #fff;
  }

  &:hover {
    color: $main;
  }

  &_icon {
    width: 72px;
    height: 72px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $main;
    margin-left: 24px;
    flex-shrink: 0;
  }
}

.wrapper {
  position: relative;
  z-index: 10;

  @include xxl {
    display: none;
  }

  &_open {
    display: block;
  }
}

.mobile_close {
  @include xxl {
    z-index: -1;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
  }

}
