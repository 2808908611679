.checkbox_formik {
  &__no {
    width: 100%;
    display: block;

    &:after {
      display: none;
    }

    span {
      padding: 0;
      display: block;
    }

    .ant-checkbox {
      display: none;
    }
  }
}
