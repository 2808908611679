@import "src/style/mixins";

.container {
  margin-top: 22px;
}

.alert {
  font-size: 18px;
  line-height: 23px;
  color: #000000;
  padding: 25px 30px;
}

.section {
  background: #FFFFFF;
  border: 1px solid rgba(168, 168, 168, 0.25);
  border-radius: 4px;
  padding: 38px 54px;

  @include md {
    padding: 30px 20px;
  }

  & + .section {
    margin-top: 30px;
  }

  h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    margin: 0;
  }
}

.studiedFrench {
  display: flex;
  margin: 9px -14px 38px;

  @include sm {
    margin: 9px -5px 10px;
  }

  button {
    width: calc(50% - 28px);
    margin: 0 14px;

    @include sm {
      width: calc(50% - 10px);
      margin: 0 5px;
    }
  }
}

.social_before {
  width: 188px;
  padding-left: 34px;

  @include sm {
    width: auto;
    padding: 0 5px;
  }
}

.add_native_lang {
  background: #EAEAEA;
  border-radius: 6px;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.1px;
  color: #000000;
  padding: 8px;
  width: 100%;
  transition-duration: .3s;
  margin-bottom: 28px;

  &:hover {
    background: darken(#EAEAEA, 5%);
  }
}
