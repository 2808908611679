@import "src/style/variables";

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.actions {
  display: flex;
  align-items: center;
  margin: 0 -10px;
  justify-content: center;

  &_item {
    margin: 0 10px;
    display: flex;
    align-items: center;

    svg {
      width: 15px;
      height: 15px;
      transition-duration: .3s;
      fill: #ABABAB;
    }

    &:hover svg {
      fill: $main;
    }

    &__delete {
      svg {
        fill: #B81A1A;
      }

      &:hover svg {
        fill: darken(#B81A1A, 10%);
      }
    }
  }
}

.search {
  margin-top: 24px;
}

.status_select {
  display: flex;
  flex-direction: column;

  button {
    margin-bottom: 5px;

    &:hover {
      color: $main;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
