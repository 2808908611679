@import "variables";

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''),
  url('../fonts/ibm-plex-sans-v8-latin_cyrillic-regular.woff2') format('woff2'),
  url('../fonts/ibm-plex-sans-v8-latin_cyrillic-regular.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local(''),
  url('../fonts/ibm-plex-sans-v8-latin_cyrillic-500.woff2') format('woff2'),
  url('../fonts/ibm-plex-sans-v8-latin_cyrillic-500.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local(''),
  url('../fonts/ibm-plex-sans-v8-latin_cyrillic-600.woff2') format('woff2'),
  url('../fonts/ibm-plex-sans-v8-latin_cyrillic-600.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(''),
  url('../fonts/ibm-plex-sans-v8-latin_cyrillic-700.woff2') format('woff2'),
  url('../fonts/ibm-plex-sans-v8-latin_cyrillic-700.woff') format('woff');
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  font-family: 'IBM Plex Sans', sans-serif;
}

img {
  max-width: 100%;
}

button {
  border: none;
  cursor: pointer;
  background: transparent;
  padding: 0;
  margin: 0;
}

.ant-badge-count {
  padding: 0 5px;
}

.page_title {
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  color: #11263C;
  margin: 0;
}

.page_description {
  font-size: 17px;
  line-height: 22px;
  color: #969696;
  margin-top: 16px;
}

.right_sitebar_back {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: $black;
  transition-duration: .3s;
  margin-bottom: 26px;
  padding: 0 40px;

  svg {
    transform: rotate(90deg);
    width: 12px;
    height: 7px;
    margin-right: 15px;
    transition-duration: .3s;
  }

  &:hover {
    color: $main;

    svg {
      fill: $main;
    }
  }
}

.hidden {
  display: none;
}

.ant-input-group-addon {
  padding: 0;
  text-align: left;
  background-color: $white;
  border-radius: 2px;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #ffffff;
}

.ant-select-item-option-content {
  white-space: normal;
}

.ant-select-item-option:hover {
  background: #6A1D51;
  color: $white;
}

.ant-select-selection-placeholder {
/*  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  justify-content: center;*/
}

.bold {
  font-weight: 600;
}

.blue {
  color: #3E97D7;
}

.user_status {
  font-size: 12px;
  line-height: 20px;
  padding: 1px 8px;
  border: 1px solid;
  border-radius: 2px;
  display: inline-block;
  margin: 0 auto;

  &--active {
    background: #F6FFED;
    border-color: #B7EB8F;
    color: #52C41A;
  }

  &--notActive {
    background: #FFF1F0;
    border-color: #FFA39E;
    color: #F5222D;
  }

  &--wait {
    background: #FFF7E6;
    border-color: #FFD591;
    color: #FA8C16;
  }
}

.schedule .fc .fc-timegrid-slot {
  height: 100px !important;
}

.btn_delete {
  background: #B81A1A;
  border: 1px solid #D9D9D9;
  color: $white;

  &:hover {
    background: darken(#B81A1A, 5%);
    border: 1px solid #D9D9D9;
    color: $white;
  }
}

.schedule .fc-timegrid-event .fc-event-main {
  padding: 0 !important;
}

.schedule .fc-v-event {
  border: none !important;
  background: transparent !important;
}

body .fc .fc-button-primary,
body .fc .fc-button-primary:disabled{
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 2px;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
  padding: 19px;

  &:hover,
  &:active {
    background: $main;
    color: #fff;
    border-color: $main;
  }
}

body .fc .fc-button-primary:not(:disabled).fc-button-active {
  background: $main;
  border-color: $main;
}

body .fc .fc-button-primary:not(:disabled):active {
  background: $main;
  color: #fff;
  border-color: $main;
}

body .fc .fc-button-primary:not(:disabled):active:focus,
body .fc .fc-button-primary:not(:disabled).fc-button-active:focus,
body .fc .fc-button-primary:focus {
  box-shadow: none;
}

body .fc .fc-toolbar-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #11263C;
}

.btn_round {
  border-radius: 46px;
}

.ant-modal-content {
  border-radius: 10px;
}
