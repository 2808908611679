@import "src/style/variables";

.container {
  height: 100%;
  overflow-y: auto;
  padding: 38px 0;

  &_inner {
  //  padding: 0 40px;
  }
}

.dialogs {
  padding: 0 40px;
  width: 100%;
  margin-top: 22px;

  &_item {
    display: flex;
    text-align: left;
    margin-bottom: 28px;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }

    &_photo {
      width: 48px;
      height: 48px;
      flex-shrink: 0;
      color: $white;
      margin-right: 16px;
      font-weight: 600;
      font-size: 16px;
      position: relative;

      &_item {
        border-radius: 16px;
        overflow: hidden;
        background-color: $main;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &_online {
        &:before {
          content: '';
          position: absolute;
          width: 12px;
          height: 12px;
          background: #2CC069;
          border: 2px solid #FFFFFF;
          top: -2px;
          right: -2px;
          border-radius: 100%;
        }
      }
    }

    &_content {
      width: 100%;
    }

    &_name {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
    }

    &_message {
      font-size: 14px;
      line-height: 20px;
      color: #ADB5BD;
      margin-top: 2px;
    }

    &_date {
      font-size: 10px;
      line-height: 16px;
      color: #A4A4A4;
    }

    &_notReadCount {
      background: #D2D5F9;
      border-radius: 40px;
      padding: 2px 8px;
      font-weight: 600;
      font-size: 10px;
      line-height: 16px;
      text-align: center;
      color: #001A83;
      margin-top: 10px;
    }
  }
}
