@import "src/style/variables";

.container {
  max-width: 818px;
  width: auto;
}

.header {
  margin: 0 0 24px;

  button {
    width: 100%;
  }
}

.actions {
  margin-top: 24px;
  text-align: center;
}

.success {
  text-align: center;

  &_link {
    margin-top: 16px;
    display: block;
  }
}

.checkboxs {
  margin-top: 20px;
}

.checkbox_container {
  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #000;
    display: flex;
    margin-bottom: 17px;
  }

  a {
    color: #963333;

    &:hover {
      color: $main;
    }
  }
}

.btn {
  width: 100%;
}
