@import "src/style/variables";
@import "src/style/mixins";

.select {
  &__container {
    margin-bottom: 24px;
    display: flex;
  }

  &__select {
    text-align: center;
    margin-left: 20px;
    width: 180px;

    @include sm {
      margin: 15px 0 0;
      width: 100%;
    }
  }
}

.lessons {
  display: block;

  &__header {
    display: flex;
    justify-content: space-between;

    @include sm {
      flex-direction: column;
    }
  }

  &__lessons {
    display: flex;
  }

  &__noLessons {
    text-align: center;
    font-size: 18px;
  }

  .lesson {
    &__titleContainer {
      border-bottom: 1px solid #d9d9d9;
      margin-bottom: 30px;

      &__countLessons {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #7C7C7C;
      }
    }

    &__title {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: $main;
      display: inline-block;
      border-bottom: 2px solid $main;
      padding: 0 0 10px;
    }

    &__description {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: black;
      border: 2px solid #ebebeb;
      box-sizing: border-box;
      border-radius: 10px;
      padding-left: 22px;
      padding-right: 22px;
      transition-duration: .3s;

      &:hover:not(&__disabled) {
        background: $main;
        border-color: $main;
        color: #ffffff;

        a {
          color: $white;
        }
      }

      &__disabled {
        opacity: .4;
      }

      .link {
        margin-bottom: 20px;
        margin-top: -14px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        text-decoration-line: underline;
        color: #3E97D7;

        &:hover {
          text-decoration: underline;
          color: #FFFFFF;
        }
      }

      .type {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        display: flex;
      }

      .timeContainer {
        display: flex;
        justify-content: space-between;

        .data {
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
          margin-top: 28px;
          margin-bottom: 8px;
        }

        .time {
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
          margin-top: 28px;
          margin-bottom: 8px;
        }
      }

      .teacher {
        font-weight: 500;
        line-height: 22px;
        font-size: 14px;
        margin-bottom: 26px;
        margin-top: 17px;
      }
    }
  }
}
