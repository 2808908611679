@import "src/style/variables";

.schedule {
  position: relative;
  overflow: hidden;
  height: 100%;

  &_delete {
    position: absolute;
    top: 0;
    right: 0;
    color: red;
    transition-duration: .3s;

    &:hover {
      color: $main;
    }
  }
}

.export {
  display: flex;
  justify-content: flex-end;
  margin: 15px 0;
}
