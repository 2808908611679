.filters {
  display: flex;
  flex-wrap: wrap;
  margin: 25px -10px 0;
  align-items: center;

  & > * {
    margin: 0 10px 20px;
  }
}

.switch {
  display: flex;
  align-items: center;

  &_title {
    margin-right: 5px;
  }
}
